import React from "react"
import SEO from "../components/SEO"

export default function PrivacyPolicy() {
    return (
        <div id="page">
            <SEO />
            <header>
                <img src="/app_logo.png" />
                <h1><a href="/">VocalChords</a></h1>
                <h2>Privacy Policy</h2>
            </header>
            <div id="content">
                <h3>Recording Data</h3>
                <p>We only use the microphone when you are recording with the record button as per the primary functionality of the app.</p>
                <p>We send no recording data to any servers.</p>
                <p>The data is under your control unless and until you choose to share it with other apps.</p>
                <h3>Analytics and Crash Reporting</h3>
                <p>If you enable the option under the More menu we will collect anonymized crash reports and analytics data to help improve the app. 
                    This uses the Firebase analytics and crashlytics libraries for data collection, server side storage, and dashboards/analysis.</p>
                <p>For the crash reporting, we capture stack traces and basic device information to use for debugging and aggregating.</p>
                <p>For analytics, along with some basic device and locale information used for aggregation and analysis, we record events
                    with timestamps about core user functionality. Specifically we collect markers for recording creation (with duration), 
                    playing, pausing, sharing, and deleting recordings, and adding and removing tags. Nothing is sent about the contents
                    of recordings other than the duration.</p>
                <p>I will be viewing this information in aggregate to identify patterns of usage to inform future feature development, or 
                    as individual sets of markers solely to help with debugging crashes.</p>
            </div>
            <footer>
                <span>Developed by <a href="https://aengusmcmillin.com">Aengus McMillin</a></span>
                <span><a href="/privacypolicy">Privacy Policy</a> -- <a href="mailto:hello@aengusmcmillin.com">Contact</a></span>
            </footer>
        </div>
    )
}